<template>
  <el-dialog class="select-dialog" :title="$t('common.select') + $t('elevator.elevator')" :visible.sync="dialogVisible"
    width="80%" top="10px" append-to-body>
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" :multi-selection="multiSelection"
      url="elevators/page" @row-click="handleRowClick" @selection-change="handleMultiSelectChange">
      <template slot="adSearch">
        <vm-search :label="$t('common.filter')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
        <vm-search :label="$t('elevator.elevatorType')">
          <el-select v-model="search.categoryCode" :style="{ width: $l('250px', '150px') }"
                     :placeholder="$t('elevator.elevatorType')" clearable>
            <el-option v-for="item in categoryList" :key="item.value" :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </vm-search>
        <vm-search :label="$t('elevator.onlineStatus')">
          <el-select v-model="search.onlineStatus" :placeholder="$t('elevator.onlineStatus')"
            :style="{ width: $l('150px', '120px') }" clearable>
            <el-option v-for="item in onlineStatusOptions" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </vm-search>
        <vm-search :label="$t('elevator.activatedPartsStatus')">
          <el-select v-model="search.hasActivatedParts" :placeholder="$t('elevator.activatedPartsStatus')"
            :style="{ width: $l('200px', '120px') }" clearable>
            <el-option v-for="item in activatedPartsStatus" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </vm-search>
        <vm-search :label="$t('elevator.joinStatus')">
          <el-select v-model="search.inNetState" :placeholder="$t('elevator.joinStatus')"
            :style="{ width: $l('150px', '120px') }" clearable>
            <el-option v-for="item in inNetStateOptions" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </vm-search>
        <vm-search v-if="$i18n.isCn" :label="$t('elevator.accessPlatform')">
          <el-select v-model="search.integrationKey" :placeholder="$t('elevator.accessPlatform')"
            :style="{ width: $l('160px', '150px') }" clearable>
            <el-option v-for="item in integrationList" :key="item.integrationKey" :label="item.name"
              :value="item.integrationKey"></el-option>
          </el-select>
        </vm-search>
      </template>
      <template slot="toolbar">
        <el-button class="addBtn" v-if="multiSelection" @click="multiSelectSure">
          {{ $t("elevator.confirmChoice") }}
        </el-button>
      </template>
      <table-column prop="no" :label="$t('elevator.no')" :width="125" align="center"></table-column>
      <table-column prop="name" :label="$t('elevator.name')"></table-column>
      <table-column prop="regNo" :label="$t('elevator.registerCode')"></table-column>
      <table-column prop="dtuCode" :label="$t('elevator.dtuCode')" align="center" :width="130"></table-column>
      <table-column prop="nodeCode" :label="$t('elevator.nodeCode')" :width="90" align="center"></table-column>
      <table-column prop="useUnitName" :label="$t('elevator.useUnit')"></table-column>
      <table-column prop="maintComName" :label="$t('elevator.maintenanced')"></table-column>
      <table-column v-if="showOrgId" prop="orgName" :label="$t('elevator.belongCompany')"></table-column>
      <table-column prop="maintEmpName" :label="$t('elevator.maintWorker')" :width="$l('105', '80')"
        align="center"></table-column>
      <table-column v-if="$i18n.isCn" :label="$t('elevator.accessPlatform')" :width="100" align="center">
        <template #default="scope">
          <span v-for="(item, index) in scope.row.elevatorIntegrationVos" :key="index">
            <span>{{ item.integrationKeyName + (index === scope.row.elevatorIntegrationVos.length - 1 ? "" : ",") }}</span>
          </span>
        </template>
      </table-column>
      <table-column v-if="false" prop="elevatorProductTypeName" :label="$t('elevator.elevatorType')"></table-column>
      <table-column v-if="false" prop="elevatorProductTypeNameEn"
        :label="$t('elevator.elevatorType')"></table-column>
      <table-column :label="$t('common.operate')" :width="85" align="center" fixed="right" :tooltip="false">
        <template #default="scope">
          <el-button type="text" @click="handleSelect(scope.row)">{{ $t("common.select") }}</el-button>
        </template>
      </table-column>
    </vm-table>
  </el-dialog>
</template>

<script>
import auth from "@/util/auth";

const moduleName = "elevators";
const moduleName_productType = "elevatorProductTypes";

export default {
  components: {},
  props: {
    multiSelection: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showOrgId: auth.getUsername() === "superAdmin",
      dialogVisible: false,
      loading: true,
      multiSelect: [],
      integrationList: [],
      maintWorkOrder: false,
      productTypeOptions: [],
      categoryList: [],
      search: {
        filter: "",
        categoryCode: "",
        integrationKey: "",
        maintComId: "",
        quantityOrganId: "",
        createComId: "",
        installComId: "",
        remakeComId: "",
        useUnitId: "",
        onlineStatus: "",
        inNetState: "",
        hasActivatedParts: "",
        leaveStartTime: "",
        leaveEndTime: "",
        installStartTime: "",
        installEndTime: "",
        inNetStartTime: "",
        inNetEndTime: "",
        useStartTime: "",
        useEndTime: "",
        districtId: "",
      },
      activatedPartsStatus: [
        { value: "false", label: this.$t("elevator.notActivated") },
        { value: "true", label: this.$t("elevator.activated") },
      ],
      onlineStatusOptions: [
        { value: 0, label: this.$t("device.offline") },
        { value: 1, label: this.$t("device.online") },
      ],
      inNetStateOptions: [
        { value: "false", label: this.$t("elevator.notJoined") },
        { value: "true", label: this.$t("elevator.joined") },
      ],
    };
  },
  methods: {
    open(filter) {
      if (filter) {
        if (filter.startsWith("useUnitId:")) {
          if (filter.split(":")[1] !== "" && filter.split(":")[1] > 0) {
            this.search.useUnitId = filter.split(":")[1];
          } else {
            this.$message.error(this.$t("common.pleaseSelect") + this.$t("elevator.useUnit"));
            return;
          }
        }else if (filter.startsWith("integrationKey:")) {
          if (filter.split(":")[1] !== "") {
            this.search.integrationKey = filter.split(":")[1];
          } else {
            return;
          }
        }else if (filter.startsWith("inNetState:")) {
          if (filter.split(":")[1] !== "") {
            let state = filter.split(":")[1];
            if (state === "1") {
              this.search.inNetState = "true";
            } else if (state === "0") {
              this.search.inNetState = "false";
            }
          } else {
            return;
          }
        }
      }
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.getList(1);
      });
      this.getProductTypeOptions();
      this.getIntegrationList();
    },
    getList(pageIndex) {
      this.loading = true;
      this.$refs.vmTable.getList(pageIndex);
      this.loading = false;
    },
    getProductTypeOptions() {
      this.$api.getList(moduleName_productType).then(response => {
        this.productTypeOptions = [];
        for (let productType of response.data) {
          let item2 = "";
          if (this.$i18n.isCn) {
            item2 = {value: productType.elevatorProductTypeNO, label: productType.elevatorProductTypeName};
          } else {
            item2 = {value: productType.elevatorProductTypeNO, label: productType.elevatorProductTypeNameEn};
          }
          this.categoryList.push(item2);
        }
      }).catch((error) => {
        if (error.response) {
          this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
        }
      });
    },
    getIntegrationList() {
      this.$http.get("integrations/list").then(({ data }) => {
        this.integrationList = data;
      });
    },
    handleSelect(row) {
      this.$emit("select", row);
      this.dialogVisible = false;
    },
    handleMultiSelectChange(val) {
      this.multiSelect = val;
    },
    multiSelectSure() {
      this.$emit("multi-select", this.multiSelect);
      this.dialogVisible = false;
    },
    handleRowClick(row, column) {
      if (column && column.label === this.$t("common.operation")) {
        return;
      }
      this.$refs.vmTable.$refs.elTable.toggleRowSelection(row);
    },
  },
};
</script>

<style lang="scss" scoped></style>
