import { render, staticRenderFns } from "./ElevatorSelect.vue?vue&type=template&id=64703b64&scoped=true"
import script from "./ElevatorSelect.vue?vue&type=script&lang=js"
export * from "./ElevatorSelect.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64703b64",
  null
  
)

export default component.exports